@font-face {
    font-family: 'helvetica';
    src: url('../fonts/Helvetica.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'helvetica_bold';
    src: url('../fonts/Helvetica-Bold-Font.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

pre.entry-subtitle, .entry-panel, .tabWrapper ,.entry-label-content, .intervention-value, .intervention-value span, .tab-item, .entry-system-content, .goals-alert-body, .today-line-label, .entry-date-container p, .entry-subtitle, .entry-notes-content, .mat-card-header span, .today-label{
    font-family: "helvetica";
  }
  h2.entry-title , .entry-pathway-label, .entry-notes-label, .entry-intervention-label, .entry-tags-container, .pathway-label, .intervention-label, .entry-status-tag, .entry-comment-content, .goals-alert-heading, .entry-tag, .withdrawl-entry-tag, .months-container span{
    font-family: 'helvetica_bold';
  }
  .today-line-label, .today-label{
    font-size: 19px;
  }