.sv_p_root{
  overflow: visible !important;
}
.text-right{
  text-align: right;
}
.pdfViewer .page{
  margin: 0 !important;
}

.svd_container .svd_toolbox .svd_toolbox_item{
  text-overflow: ellipsis;
}

.header .tabWrapper ul li.tabHead a, .main-tab-container .tabWrapper ul li.tabHead a {
  float: none!important;
}

.deactivatedDate {
  padding: 4px 12px !important;
  background-color: #1b2040;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
  margin-right: 0% !important;
}

#edit_service_user .deactivatedDate, #view_user-plan .deactivatedDate {
  position: absolute;
  top: 2em;
  left: 40%;
}

#edit_service_user .action-buttons, #view_user-plan .action-buttons {
  float: right;
}

#view_user-plan .mat-card-header {
  display: block;
}
.mat-form-field-appearance-outline .mat-form-field-flex{
  padding: .3em .75em 0 .75em !important;
}
.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix{
  top: .4em !important;
}

.profileImgSectionMob {
  display: none;
}
.profileImgSection {
  display: block;
}
@media screen and (max-width: 767px) {
  .profileImgSectionMob {
    display: block;
  }
  .profileImgSection {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .main-container {
    left: 0px;
  }

  .push-right {
    #sidebar {
      left: 0 !important;
    }
  }
  #additionalSidebarContainer .invisible {
    display: block !important;
  }
  .nav-brand{
    width: 130px;
    padding-left: 10px;
    height: auto;
    text-align: left;
  }
  .svd_container .svd_toolbox .svd_toolbox_title{
    display: none;
  }
  .svd_container .svd_toolbox .svd_toolbox_item{
    text-overflow: clip !important;
  }
  .seq-theme .mat-card .mat-card-header button {
    margin-bottom: 5px;
  }
  #top-nav .nav-brand .logo-name {
    font-size: 14px;
    vertical-align: middle;
  }
  #navbar .mat-toolbar .nav-brand {
    width: 90px;
    padding-left: 0;
    height: 100%;
    text-align: left;
  }
  #edit_keyword .mat-card-footer{
    padding: 20px 0;
  }
  
}

@media (max-width: 960px){
  .seq-theme .search_by .mat-form-field-infix {
    width: calc(100% - 24px);
  }
  .seq-theme .mat-card .mat-cell.mat-column-accountState, .seq-theme .mat-card .mat-cell.mat-column-accountState .mobile-label, .seq-theme .mat-card .mat-cell.mat-column-isActive, .seq-theme .mat-card .mat-cell.mat-column-isActive .mobile-label, .seq-theme .mat-card .mat-cell.mat-column-jobApplicationStatus-statusDescription, .seq-theme .mat-card .mat-cell.mat-column-jobApplicationStatus-statusDescription .mobile-label, .seq-theme .mat-card .mat-cell.mat-column-jobStatus, .seq-theme .mat-card .mat-cell.mat-column-jobStatus .mobile-label, .seq-theme .mat-card .mat-cell.mat-column-sessionState, .seq-theme .mat-card .mat-cell.mat-column-sessionState .mobile-label, .seq-theme .mat-card .mat-cell.mat-column-status, .seq-theme .mat-card .mat-cell.mat-column-status .mobile-label {
    display: inline!important;
  }
  .seq-theme .mat-card .mat-cell {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: left;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: auto;
    margin: 10px 0;
  }
  .seq-theme .mat-card .mat-cell:last-of-type {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: flex-start;
    min-height: 12px;
    margin-left: -4px;
    padding-bottom: 4px;
  }
  .seq-theme .mat-card .mat-row:nth-of-type(odd) {
    border-top: 1px solid rgba(0,0,0,.12);
    border-bottom: 1px solid rgba(0,0,0,.12);
  }
  .seq-theme .mat-card .mobile-label {
    font-size: 14px;
    color: #2b769b;
    display: block;
    font-weight: 600;
  }
  .seq-theme .mat-card .mat-cell:first-of-type, .seq-theme .mat-card .mat-footer-cell:first-of-type, .seq-theme .mat-card .mat-header-cell:first-of-type {
    padding-left: 0;
    padding-top: 8px;
  }
  .seq-theme .mat-card .mat-header-row {
    display: none!important;
  }
  .seq-theme .mat-card .mat-row {
      display: block;
  }
  .messages-page .messages-container .message-list-left .govuk-grid-row .govuk-grid-column-one-third .moj-side-navigation .moj-side-navigation__item .kw-avatar-circle{
    margin: 10px;
    margin-top: 10px!important;
  }
  .messages-page .messages-container .message-list-left .govuk-grid-row .govuk-grid-column-one-third .moj-side-navigation .moj-side-navigation__item .message-item{
    display: none;
  }
  #surveyEditorContainer .svd_container.sv_default_css .svd_toolbox {
      width: auto;
      display: inline-block;
  }
  .messages-page .messages-container .message-list-right .full-width {
    padding-top: 10px!important;
  }
  .hidden-sm, .hidden-xs, .hide-in-mobile {
    display: none;
  }
  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    padding: 0 !important;
  }

}
@media screen and (max-width: 992px) and (orientation: portrait){
  .svd_container .svd_toolbox .svd_toolbox_item{
    text-overflow: ellipsis !important;
  }
  
}
// @media all and (max-width:1024px){
//   .zoom .invisible{
//     visibility:visible !important;
//     display: inline !important;
//    }
  
// }
@media all and (max-width:375px){
  #category .mat-card-header{
    padding-bottom: 57px;
  }
  .filter_comp{
    left: 0px;
    position: absolute !important;
    top: 63px;
  }
  .filter_comp .filter_list {
    left: 10px;
  }
}
@media screen and (min-width: 360px) and (max-width: 778px) and (orientation: portrait){
  .svd_container .svd_toolbox .svd_toolbox_item{
    text-overflow: clip !important;
  }
  .nav-brand{
    padding-left: 0 !important;

  }
  .nav-brand a .logo-name{
    margin-left: 2px !important;
    font-size: 15px !important;
    margin-top: 6px !important;
  }


}

@media (max-width: 960px) {
  .messages-page .messages-container .message-list-left .govuk-grid-row .govuk-grid-column-one-third .moj-side-navigation .moj-side-navigation__item .avatar-circle, .messages-page .messages-container .message-list-left .govuk-grid-row .govuk-grid-column-one-third .moj-side-navigation .moj-side-navigation__item .kw-avatar-circle {
    margin: 10px;
    margin-top: 10px!important;
  }

  .messages-page .messages-container .message-list-left {
    width: auto!important;
    margin-right: 10px!important;
  }

  .messages-page .messages-container .message-list-left .govuk-grid-row .govuk-grid-column-one-third .moj-side-navigation .moj-side-navigation__item {
    min-height: auto!important;
  }

  .main-container {
      left: 0;
      margin: calc(24px + 56px) 12px 12px;
      padding: 20px 10px!important;
  }

  .seq-theme .mat-card {
    padding: 15px 10px;
  }

  #surveyEditorContainer .svd_container .svd_toolbox .svd_toolbox_item {
    width: auto;
  }

  #surveyEditorContainer .svd_container .svd_content {
    padding: 20px 10px;
  }

  .messages-page {
    padding: 20px 10px!important;
  }

}

@media  screen and (min-device-width : 414px){
  .messages-page .messages-container .message-list-right{
    overflow-y: visible !important;
    overflow-x: visible !important;
    height: 100%;
  }

}
 
  @media screen and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .svd_container .svd_toolbox .svd_toolbox_item{
      text-overflow: ellipsis !important;
    }

  }

  @media (max-width: 600px) {
    .seq-theme .mat-card .mat-cell.mat-column-accountState, .seq-theme .mat-card .mat-cell.mat-column-accountState .mobile-label, .seq-theme .mat-card .mat-cell.mat-column-isActive, .seq-theme .mat-card .mat-cell.mat-column-isActive .mobile-label, .seq-theme .mat-card .mat-cell.mat-column-jobApplicationStatus-statusDescription, .seq-theme .mat-card .mat-cell.mat-column-jobApplicationStatus-statusDescription .mobile-label, .seq-theme .mat-card .mat-cell.mat-column-jobStatus, .seq-theme .mat-card .mat-cell.mat-column-jobStatus .mobile-label, .seq-theme .mat-card .mat-cell.mat-column-sessionState, .seq-theme .mat-card .mat-cell.mat-column-sessionState .mobile-label, .seq-theme .mat-card .mat-cell.mat-column-status, .seq-theme .mat-card .mat-cell.mat-column-status .mobile-label{
      display: inline !important;
    }
    .seq-theme .mat-card .mobile-label{
      display: block !important;
    }
  }
.seq-theme {
  @media (max-width: 598px) {
    .mat-card {
      .card-row {
        flex-direction: column;
      }

      .card-column {
        width: 100%;
      }

      .card-column-third {
        width: 100%;
      }

      .card-column-two-third {
        width: 100%;
      }
      .mat-row {
        display: block;
      }

      .mat-header-row {
        display: none !important;
      }

      .mat-cell {
        display: flex;
        flex: 1 1 100%;
        width: 100%;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        justify-content: center;
        min-height: 45px;
        margin: 10px 0;
      }

      .mat-cell:last-of-type {
        flex-direction: row;
        justify-content: flex-start;
        min-height: 12px;
        margin-left: -4px;
        padding-bottom: 4px;
      }

      .mat-cell:first-of-type,
      .mat-footer-cell:first-of-type,
      .mat-header-cell:first-of-type {
        padding-left: 0;
      }

      .mat-row:nth-of-type(odd) {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      .mat-footer-row::after,
      .mat-header-row::after,
      .mat-row::after {
        display: none;
      }
     
    }

    .label-hr span {
      font-size: 1.2em;
    }

    .search_by {
      .mat-form-field-infix {
        width: calc(100% - 24px);
      }
    }

    .mobile,
    .on-mobile {
      display: block !important;
    }

    .desktop,
    .on-desktop {
      display: none;
    }

      #surveyEditorContainer .svd_container .svd_toolbox .svd_toolbox_item {
        width: auto;
    }

    .ng-star-inserted .svd_container .svd_content{
      padding: 20px 10px;
    }

    .svd_container.sv_default_css .svd_editors {
      display: inline-block;
      width: 85%;
      padding: 0 !important;
    }

    .svd_container.svd_container .icon-white .svd-svg-icon{
     display: none;
    }

    .svd-toolbar-button__image{
      display: none;
  }

  }
  .mat-cell > span.call-status-label {
    position: relative;
    .status {
      position: absolute;
      left: 5em;
      padding: 2px 7px 3px !important
    }
  }
  .status {
    padding: 2px 7px 3px !important
  }
 

  .svd_container.svd_container .icon-white .svd-svg-icon{
    fill: rgb(61, 77, 93) !important;
  }
  
}
@media screen and (max-width: 992px){
  .infoSection {
      margin-top: 0 !important;
  }
  .seq-theme .search_by{
    padding: 0 0.75em !important;
  }
}
@media (max-width: 767px) {
  #edit_service_user .deactivatedDate, #view_user-plan .deactivatedDate {
      margin: 1em 0 0;
      text-align: center;
      position: initial;
  }
  .message-list-right {
    overflow: initial!important;
  }
  .estb-container {
    height: 0vh !important;
  }
  .cdk-overlay-pane{
    min-width: 265px !important;
    left: 62px !important;
  }
  .profileImgSectionMob {
    display: block;
  }
  .profileImgSection {
    display: none;
  }
   #toolbarContainer{
    pdf-page-number{
      width: 163px;
    }
    pdf-paging-area{
      .splitToolbarButton{
        display: block!important;
      }
      pdf-page-number{
        display: block;
        min-width: 163px;
      }
    }
  }
}
pdf-presentation-mode{
  .toolbarButton{
    display: block!important;
    position: absolute;
    right: 25px;
  }
}
.splitToolbarButton.hidden{
  display: block!important;
  width: 160px;
}
pdf-zoom-toolbar{
  #toolbarViewerMiddle{
    position: absolute!important;
    right: 60px!important;
  }
}
pdf-toggle-sidebar {
  .toolbarButton.invisible{
    display: block!important;
  }
}
#outerContainer.sidebarOpen {
  #sidebarContainer{
    top: 0!important;
  }
}
@media (max-width: 480px) {
  .splitToolbarButton.hidden{
    display: block!important;
    width: 160px;
  }
  .hiddenSmallView *{
    display: block!important;
  }
}
@media all and (max-width: 767px) {
  .message-list-right {
    overflow: initial !important;
  }
  .seq-theme {
    #edit_content_image {
      .mat-form-field-appearance-legacy {
        .mat-form-field-underline,
        .mat-form-field-subscript-wrapper {
          position: initial !important;
        }
      }
    }
  }
  .logo-name{
    display: none!important;
  }
}

/* for mobile devices */


